import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import "./index.css"

const ContactPage = ({ data }) => {
  return (
    <Layout title="Contact">
      <SEO title="Contact | Leonie Freudenberger" />
      <article className="contact">
        <div
          dangerouslySetInnerHTML={{
            __html:
              data.contentfulHomepage.contactText.childMarkdownRemark.html,
          }}
        ></div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulHomepage(id: { eq: "4391cc10-a551-5b94-8e58-5cdc5e937532" }) {
      contactText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default ContactPage
